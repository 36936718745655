<template>
  <section class="space-y-10">
    <QuickInfo />
    <article v-if="canCreateCashOrder || isAdminRole">
      <h3 class="subheader">
        Quick Links
      </h3>
      <br />
      <section
        class="relative z-0  rounded-md grid sm:grid-cols-1 md:grid-cols-4"
      >
        <asom-button
          v-if="canCreateCashOrder"
          variant="primary"
          outline
          rounded="none"
          @click="$router.push('cash-order/new')"
          icon="swap"
          text="New Cash Order"
        >
        </asom-button>
        <asom-button
          v-if="isAdminRole"
          variant="primary"
          outline
          rounded="none"
          @click="$router.push('cash-order/report')"
          icon="swap"
          text="Cash Order Report"
        >
        </asom-button>
      </section>
    </article>
    <admin-view v-if="isAdminRole" />
    <!--<non-admin-view v-else />-->
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import QuickInfo from "../_QuickInfo.vue";
// import NonAdminView from "./_NonAdminMainPage.vue";
import AdminView from "./_AdminMainPage.vue";

export default {
  components: {
    QuickInfo,
    AdminView,
    // NonAdminView,
  },
  computed: {
    ...mapGetters({
      isAdminRole: "auth/canViewAdminCashOrderReport",
      canCreateCashOrder: "auth/canCreateCashOrder",
    }),
  },
};
</script>
