<template>
  <section class="space-y-10">
    <article>
      <asom-alert
        class="mb-4"
        v-if="error"
        variant="error"
        :error-message="error"
      />
      <div class="w-full text-center" v-else-if="isLoading">
        <asom-icon icon="spinner" class="animate-spin" />
      </div>
      <asom-client-table
        v-else
        :data="list"
        :columns="tableColumns"
        :sortableColumns="tableColumns"
        :td-classes="tableTdClass"
        :searchableDateColumns="['requestedDeliveryDate', 'orderDate']"
        :searchableCurrencyColumns="['expectedAmount', 'receivedAmount']"
      >
        <template v-slot:header_referenceNo>Reference No</template>
        <template v-slot:referenceNo="scopedSlots">
          <div class="flex flex-row">
            <div class="flex pl-2">
              <asom-link @click="navigateToView(scopedSlots.rowData)"
                >{{ scopedSlots.data }}
              </asom-link>
            </div>
          </div>
        </template>
        <template v-slot:header_cashOrderStatus>Status</template>
        <template v-slot:header_requestorName>To</template>
        <template v-slot:header_supplierName>From</template>
        <template v-slot:cashOrderStatus="slotScoped">
          <span
            class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full text-center text-gray-800"
            :class="{
              'bg-green-100':
                get(slotScoped.rowData, 'cashOrderStatusEnum') ==
                cashOrderType.DELIVERY_FULFILLED.VALUE,
              'bg-yellow-100':
                get(slotScoped.rowData, 'cashOrderStatusEnum') ==
                  cashOrderType.PENDING_DELIVERY.VALUE ||
                get(slotScoped.rowData, 'cashOrderStatusEnum') ==
                  cashOrderType.IN_REVIEW.VALUE ||
                get(slotScoped.rowData, 'cashOrderStatusEnum') ==
                  cashOrderType.PENDING_PICKUP.VALUE ||
                get(slotScoped.rowData, 'cashOrderStatusEnum') ==
                  cashOrderType.PENDING_CONFIRM_PICKUP.VALUE,
              'bg-red-100':
                get(slotScoped.rowData, 'cashOrderStatusEnum') ==
                  cashOrderType.DELIVERY_REJECTED.VALUE ||
                get(slotScoped.rowData, 'cashOrderStatusEnum') ==
                  cashOrderType.REJECTED.VALUE,
            }"
          >
            {{ slotScoped.data }}
          </span>
        </template>
        <template v-slot:header_expectedAmount>Expected Amount</template>
        <template v-slot:header_receivedAmount>Received Amount</template>
        <template v-slot:header_requestedDeliveryDate
          >Requested Delivery Date</template
        >
        <template v-slot:header_isUrgent>Urgent?</template>
        <template v-slot:orderDate="slotScoped">
          {{ displayUtcDate(slotScoped.data) }}
        </template>
        <template v-slot:requestedDeliveryDate="slotScoped">
          {{ displayUtcDate(slotScoped.data) }}
        </template>
        <template v-slot:to="slotScoped">{{
          slotScoped.rowData.requestorName
        }}</template>
        <template v-slot:supplierName="slotScoped">{{
          slotScoped.rowData.supplierName || "Contractor"
        }}</template>
        <template v-slot:expectedAmount="slotScoped"
          >{{ formatCurrency(slotScoped.data) }}</template
        >
        <template v-slot:receivedAmount="slotScoped">{{
          slotScoped.data == 0 ? "-" : formatCurrency(slotScoped.data)
        }}</template>
        <template v-slot:isUrgent="slotScoped">{{
          slotScoped.data ? "yes" : "no"
        }}</template>
        <template v-slot:action="slotScoped">
          <div class="flex flex-col gap-1 w-48">
            <asom-button
              v-if="slotScoped.rowData.cashOrderStatusEnum === 1"
              size="sm"
              outline
              text="Approve"
              @click="approve(slotScoped.rowData.cashOrderId)"
            />
            <asom-button
              v-if="slotScoped.rowData.cashOrderStatusEnum === 1"
              size="sm"
              outline
              variant="error"
              text="Reject"
              @click="reject(slotScoped.rowData.cashOrderId)"
            />
          </div>
        </template>
      </asom-client-table>
    </article>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import get from "lodash.get";
import { displayUtcDate } from "@/helpers/dateTimeHelpers";
import {
  getAdminCashOrderList,
  approveCashOrder,
  rejectCashOrder,
} from "@/services/cashManagement.service";
import { cashOrder } from "../../../constants/APIEnums/cashManagementEnums";
import { formatCurrency } from "../../../helpers/numbers";

export default {
  data() {
    return {
      list: [],
      error: null,
      isLoading: false,
      tableTdClass: {
        isUrgent: {
          "bg-yellow-100 text-yellow-800": (rowData) => rowData.isUrgent,
        },
      },
      cashOrderType: cashOrder.cashOrderStatus,
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {
    ...mapGetters({
      afcId: "cashManagement/stationCashAccounts/afcId",
      canApproveCashOrder: 'auth/canApproveCashOrder',
    }),
    tableColumns() {
      let columns = [
        'referenceNo',
        'cashOrderStatus',
        'requestorName',
        'supplierName',
        'expectedAmount',
        'receivedAmount',
        'orderDate',
        'requestedDeliveryDate',
        'isUrgent',
      ]
      if (this.canApproveCashOrder)
        columns.push('action')
      return columns;
    }
  },
  methods: {
    get,
    displayUtcDate,
    formatCurrency,
    navigateToView(cashOrder) {
      this.$router.push({
        name: "View Cash Order",
        params: cashOrder,
      });
    },
    async loadData() {
      this.error = null;
      this.isLoading = true;
      await this.$store.dispatch("cashManagement/loadStationCashAccounts");
      const result = await getAdminCashOrderList({ aFCId: this.afcId });
      if (result.success) {
        this.list = get(result, "payload.list", []);
      } else {
        this.error = result.payload;
      }
      this.isLoading = false;
    },
    async approve(cashOrderId) {
      this.error = null;
      const result = await approveCashOrder({
        cashOrderId,
      });
      if (result.success) {
        this.loadData();
      } else {
        this.error = result.payload;
        this.$scrollTop();
      }
    },
    async reject(cashOrderId) {
      this.error = null;
      const result = await rejectCashOrder({
        cashOrderId,
      });
      if (result.success) {
        this.loadData();
      } else {
        this.error = result.payload;
        this.$scrollTop();
      }
    },
  },
};
</script>
